<template>
	<div class="page_box" v-loading="loading">
	 
		<div class="bom_box">
			<div class="bom_btn_flex">
				 {{page_title}}
			 
			</div>
			<div class="form_box">
				<div class="fomr_flex">
					<div class="lefts"><span>*</span>劳保用品下发名称</div>
					<div class="rights">
						<div class="inputs1">
							<el-input v-model="input" placeholder="请输入劳保用品下发名称"></el-input>
						</div>
					</div>
				</div>
				<div class="fomr_flex">
					<div class="lefts"><span>*</span>选择月份</div>
					<div class="rights">
						<div class="inputs2">
							<el-date-picker v-model="forms.month" :clearable="false" value-format="yyyy-MM-dd" type="month" :disabled="$route.query.time?true:false"
							placeholder="选择月" :editable="false" :picker-options="pickerOptions"></el-date-picker>
						</div>
					</div>
				</div>
				 
				<div class="fomr_flex">
					<div class="lefts"> </div>
					<div class="rights">
						<div class="btn_flex">
							<div class="btn1">提交</div>
							<div class="btn2">取消</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	 
	</div>
</template>
<script>
 

export default {
	components: {
	 
	},
	data() {
		return {
			loading: false, //加载总
			tab_show: false,
			page_title: "", //页面名称  来之路由
			check_list: [], //选中的
			//提交数据
			forms: {
				keyword: "",
				// depart_id: "",
				month: '',
			},
			bm_list: [],//部门
			bm_list_ac: [],//部门选中
			props: {
				multiple: false,
				value: 'id',
				label: 'title'
			},
		 
		 
			times: [], //时间范围

			//字段
			column_group: [{
				parent_title: "基本信息",

				child: [
					{
						id: "user.name",
						title: "姓名",
						disabled: true,
						ac_show: true,
					},
					{
						id: "user.depart_title",
						title: "部门",
						disabled: true,
						ac_show: true,

					},
					{
						id: "user.level6",
						title: "岗位",
						disabled: true,
						ac_show: true,

					},
					{
						id: "atten_day",
						title: "出勤",
						disabled: true,
						ac_show: true,
					},
				],
			},
			],
			column_group2: [],
		 
		};
	},

	methods: {
		confirm_bumen_cascader_value(value, bumen_items, bumen_titles) {
			//this.$log('勾选部门数据', value)
			this.bm_list_ac=value
		},
		//获取部门
		get_bm_list() {
			this.loading = true
			this.$api("getCategoryAllList", {

			}, "get").then((res) => {
				console.log('所有的部门', res)
				this.loading = false
				if (res.code == 200) {
					let level = 0//最高等级
					let list = []
					let data = []
					res.data.forEach((e) => {
						if (e.is_gw != 1) {
							data.push(e)
						}
					})
					data.forEach((e, i) => {
						if (e.level > level) {
							level = e.level
						}
						if (e.is_gw != 1) {
							this.$set(e, 'children', [])
						}
					})
					console.log('最高登记', level)


					for (let i = 1; i <= level; i++) {
						let level_list = []
						data.forEach((e) => {
							if (e.level == i) {
								level_list.push(e)
							}


						})
						list.push(level_list)
					}
					console.log('列表1', list)
					// 总列表  最后一位的下标
					this.bm_list_data(list, list.length - 1)
				} else {
					alertErr(res.msg)
				}
			});
		},
		//处理部门列表
		bm_list_data(lists, indexs) {
			let index1 = indexs //当前下标
			let index2 = indexs - 1 //上级下标
			let list = lists
			list[index1].forEach((e) => {
				list[index2].forEach((v) => {
					if (v.id == e.parent_id) {
						v.children.push(e)
					}
				})
			})
			list[index2].forEach((e) => {
				if (e.children.length == 0) {
					this.$delete(e, 'children')
				}
			})
			if (index2 == 0) {
				console.log('最后的数据', list)
				this.bm_list = list[0]
				return
			}
			this.bm_list_data(list, index2)
		},


		 

		//岗位薪资设置
		 
	 
	 
		 
	 
		get_type() {
			this.loading = true;
			this.$api("feeProjectList", {
				// mobile:this.mobile
				...this.forms,
				...this.pages,
			}, "post").then((res) => {
				this.loading = false;
				if (res.code == 200) {
					console.log('配置列表', res)
					let obj = {};
					let list = res.data
					list.forEach((item, index) => {
						item.ac_show = true
						// this.$set(item,'ac_show',true)
						let { parent_title } = item;
						if (!obj[parent_title]) {
							obj[parent_title] = {
								parent_title,
								child: [],//全部的
							}
						}
						obj[parent_title].child.push(item);
					});
					let data = Object.values(obj);
					console.log('结果', data)
					this.column_group2 = data


				} else {
					alertErr(res.msg);
				}
			});
		},
	 
	},
	computed: {},
	watch: {},
	created() {
		this.page_title = this.$route.meta.title;
		let time=new Date().getFullYear()+'-'+((new Date().getMonth()+1)>=10?'':'0')+(new Date().getMonth()+1)
		this.forms.month = time
		// this.get_bm_list()
	 
		this.get_type()

	},
	mounted() { },
};
</script>

<style scoped lang="less">


.page_box {
	height: 100%;
	background: #ffffff;

	.bom_box {
		.bom_btn_flex {
			width: 100%;
			height: 51px;
			padding:  20px;
			font-family: Microsoft YaHei, Microsoft YaHei;
			font-weight: bold;
			font-size: 14px;
			color: #333333;
		}
		.form_box{
			padding-left: 55px;
			.fomr_flex{
				display: flex;
				// align-items: center;
				margin-bottom: 16px;
				.lefts{
					width: 130px;
					height: 40px;
					line-height: 40px;
					flex-shrink: 0;
					text-align: right;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;
					padding-right: 10px;
					span{
						font-weight: 400;
						font-size: 14px;
						color: #FF0000;
						margin-right: 10px;
					}
				}
				.rights{
					.inputs1{
						width: 897px;
						height: 40px;
						// background: #FFFFFF;
						// border-radius: 0px 0px 0px 0px;
						// border: 1px solid #E8E8E8;
					}
					.inputs2{
						width: 897px;
						height: 40px;
						/deep/.el-date-editor{
							width: 897px;
							height: 40px;
						}
					}
					.inputs3{
						width: 897px;
						height: 40px;
						/deep/.el-cascader{
							width: 897px;
							height: 40px;
						}
					}
					.btn_flex{
						display: flex;
						align-items: center;
                        padding-top: 44px;
						.btn1{
							width: 179px;
							height: 44px;
							background: #2373C8;
							margin-right: 18px;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							text-align: center;
							line-height: 44px;
							cursor: pointer;
						}
						.btn2{
							width: 179px;
							height: 44px;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #9D9D9D;
							text-align: center;
							line-height: 44px;
							cursor: pointer;			
							font-weight: 400;
							font-size: 16px;
							color: #4A4A4A;

						}
					}
				}
			}	
		}






		 
	}
}
</style>